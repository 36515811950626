import TextareaAutosize from 'react-autosize-textarea';
import styled from 'styled-components';

// const TextArea = () => <StyledTextArea />;

const Form = styled.form`
  fieldset {
    border: 0;
    padding: 0px;

    &[disabled] {
      opacity: 0.5;
    }
  }
  label {
    font-size: 1.2rem;
    color: ${(props) => props.theme.labelText};
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 16.9px;
    margin-bottom: 8px;
  }
`;

const FormSplitLayout = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 64px 16px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    grid-gap: 16px 16px;
    grid-template-columns: 100%;
    /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
    > div:nth-of-type(even) {
      padding-bottom: 32px;
    }
  }
`;

const FormColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px 16px;
`;

const Input = styled.input`
  background-color: ${(props) =>
    props.color === 'dark'
      ? props.theme.inputDarkColor
      : props.theme.inputColor};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.bg};
  padding: 0.6em;
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
  width: 100%;
  height: 48px;
  border-left: 3px solid transparent;
  box-shadow: ${(props) => props.theme.bsInset};
  &:focus {
    border-left: 3px solid ${(props) => props.theme.primary};
  }
`;

const SearchInput = styled(Input)`
  padding: 0.6em 0.5em 0.7em 2em;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
  background-repeat: no-repeat;
  background-position: 6px 14px;
  background-size: 1em 1em;
  background-color: ${(props) => props.theme.colors.white};
`;

const FileInput = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

const TextArea = styled(TextareaAutosize)`
  background-color: ${(props) => props.theme.inputColor};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.bg};
  padding: 0.6em;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  width: 100%;
  min-height: 48px;
  margin-bottom: 2.4rem;
  box-shadow: ${(props) => props.theme.bsInset};
  &:focus {
    border-left: 3px solid ${(props) => props.theme.primary};
  }
`;

const LevelGridItem = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.padding};
  div:nth-child(0) {
    grid-column: 1 / 2;
  }

  grid-template-columns: 1fr 1fr 1fr;
  h4,
  p,
  label,
  textarea,
  div {
    grid-column: 1 / 4;
  }
`;

const LevelGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-row-gap: 50px;
`;

export {
  Form,
  FormSplitLayout,
  FormColumnLayout,
  Input,
  FileInput,
  TextArea,
  LevelGrid,
  LevelGridItem,
  SearchInput,
};
