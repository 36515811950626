import React from "react"
import { graphql } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from '../styles/buttons'
import { Card, CardBody } from "../styles/card"
import { FlexBlock, GridBlock, GridRowToTopOnTablet, Section, Spacer, Wrapper, WrapperNarrow } from "../styles/layout"
import { BodyCopy1, Heading1, Heading2, Heading3, Heading4, Heading5, HeadingLarge } from "../styles/typography"
import IconSchoger from "../ui/icons/IconSchoger"
import bgSwoopUp from '../../static/assets/bg-swoop-up-left.svg';
import iconBV from '../../static/assets/icon-betavision.svg';
import { Form, Input } from "../styles/form"
import heroVideo from "../../static/assets/heroVideo.mp4"
import betavisionImgSEO from "../../static/assets/betavisionImgSEO.png"


export const pageQuery = graphql`
  query BVQuery{
    heroImg: file(relativePath: { eq: "bv-capture.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 261, maxHeight: 559, quality: 80, srcSetBreakpoints: [960, 1440]) {
          ...GatsbyImageSharpFluid
        }
        sizes {
          src
        }
      }
    }
    connectImg: file(relativePath: { eq: "bv-connect-modal.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 261, maxHeight: 559, quality: 80, srcSetBreakpoints: [960, 1440]) {
          ...GatsbyImageSharpFluid
        }
        sizes {
          src
        }
      }
    }
  }
`


const BetaVisionSoon = ({ data }) => {

  // const { heroImg, connectImg } = data // data.markdownRemark holds your post data
  // const HeroImg = heroImg ? heroImg.childImageSharp.fluid : ""
  // const ConnectImg = connectImg ? connectImg.childImageSharp.fluid : ""


  return (
    <Layout className="page">
      <SEO
        title="BetaVision by golfBeta - a video analysis app to improve your golf game"
        description="Coming soon - golf video analysis powered by Artificial Intelligence"
        image="/assets/betavisionImgSEO.png"
      />
      <HeroImgContainer>

        <HeroOverlay>

          <VideoPlayer autoPlay muted loop playsinline>
            <source src={heroVideo} type="video/mp4" />
          </VideoPlayer>
          <Wrapper>
            <GridBlock columns="1fr 1fr" responsive>
              <HeroText direction="column" align="start">
                <img src={iconBV} alt="BetaVision icon" />
                <Spacer height={1} />
                <HeadingLarge color="principleDark" align="left" margin>
                  BetaVision
                </HeadingLarge>
                <Heading3 color="principle" margin>
                  Video analysis to help improve your golf game
                </Heading3>
                <Spacer height={2} />
                <Heading2 color="accent">
                  COMING SOON
                </Heading2>
              </HeroText>
            </GridBlock>
          </Wrapper>
        </HeroOverlay>
        <BgSwoop bg={bgSwoopUp} />
      </HeroImgContainer>
      <Section>
        <WrapperNarrow>
          <GridBlock columns="100%">
            <StyledCard>
              <CardBody nicePadding>
                <Heading3>
                  Feedback on your swing in real-time
                </Heading3>
                <Heading5 color="principle" margin>
                  Swing movement detection powered by AI
                </Heading5>
                <IconSection>
                  <IconSchoger icon="tick" context="success" />
                  <BodyCopy1>
                    Choose a body part to track
                  </BodyCopy1>
                  <IconSchoger icon="tick" context="success" />
                  <BodyCopy1>
                    Set an area through which you want to swing
                  </BodyCopy1>
                  <IconSchoger icon="tick" context="success" />
                  <BodyCopy1>
                    Receive audio and visual feedback in real-time
                  </BodyCopy1>
                </IconSection>

              </CardBody>
              <Spacer height={5} />
              <CardBody nicePadding color>
                <Heading3>
                  See your swing from another angle
                </Heading3>
                <Heading5 color="principle" margin>
                  Connect to another iOS device
                </Heading5>
                <IconSection>
                  <IconSchoger icon="tick" context="success" />

                  <BodyCopy1>
                    Real-time feedback
                  </BodyCopy1>
                  <IconSchoger icon="tick" context="success" />
                  <BodyCopy1>
                    Ultra low latency
                  </BodyCopy1>
                  <IconSchoger icon="tick" context="success" />
                  <BodyCopy1>
                    Remote recording
                  </BodyCopy1>
                  <IconSchoger icon="tick" context="success" />
                  <BodyCopy1>
                    Wireless video sharing
                  </BodyCopy1>
                </IconSection>

                <Spacer height={5} />
              </CardBody>

              <CardBody nicePadding>
                <Heading3>
                  Capture and analyse in slow motion
                </Heading3>
                <Heading5 color="principle" margin>
                  Up to 240fps to analyse every detail
                </Heading5>
                <IconSection>
                  <IconSchoger icon="tick" context="success" />
                  <BodyCopy1>
                    Capture slow motion at 240fps
                  </BodyCopy1>
                  <IconSchoger icon="tick" context="success" />
                  <BodyCopy1>
                    Maintain your own library of videos
                  </BodyCopy1>
                  <IconSchoger icon="tick" context="success" />
                  <BodyCopy1>
                    Playback and analyse on the screen
                  </BodyCopy1>
                  <IconSchoger icon="tick" context="success" />
                  <BodyCopy1>
                    Video editing
                  </BodyCopy1>
                </IconSection>
                <Spacer height={5} />
              </CardBody>
              <CardBody nicePadding color>
                <Heading3>
                  Store it all in the cloud
                </Heading3><Heading5 color="principle">
                  Save space on your devices
                </Heading5>
                <IconSection>
                  <IconSchoger icon="tick" context="success" />
                  <BodyCopy1>
                    Up to 10gb of cloud storage
                  </BodyCopy1>
                  <IconSchoger icon="tick" context="success" />
                  <BodyCopy1>
                    Optimise on device storage based on your vide viewing habits
                  </BodyCopy1>
                </IconSection>

                <Spacer height={5} />
              </CardBody>

              <CardForm nicePadding>
                <Heading5 color="white" margin>
                  We can let you know when we're ready to lauch
                </Heading5>
                <Form action="/thanks" name="betavision" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/thanks">
                  <FlexBlock direction="column">
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="betavision" />
                    <Input input type="email" name="email" required placeholder="john@doe.com" />
                    <Button type="sumbit">Let me know</Button>

                  </FlexBlock>
                </Form>
              </CardForm>
            </StyledCard>
            <div>

            </div>
          </GridBlock>

        </WrapperNarrow>
      </Section>
    </Layout >
  )
}

export default BetaVisionSoon

const StyledCard = styled(Card)`
  box-shadow: ${({ theme }) => theme.bsSpreadLarge};
  width: 100%;
  margin-top: -100px;
`
const CardForm = styled(CardBody)`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.colors.white};
`

const SectionGrey = styled(Section)`
background-color: ${props => props.theme.colors.offWhite};
`

const Strapline = styled(Heading1)`
position: absolute;
left: 1.6rem;
bottom: 1.6rem;
padding: 1.6rem;
background-color: rgba(0,0,0,0.5);
max-width: 500px;
width: 100%;
border-radius: ${({ theme }) => theme.borderRadius};
box-shadow: ${({ theme }) => theme.bsSpreadLarge};
`

const HeroImgContainer = styled.div`
  height: -webkit-fill-available;
  height: 800px;
  overflow: hidden;
  position: relative;
  .gatsby-image-wrapper {
    position: initial;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.tablet}) {
    height: 600px;
    .gatsby-image-wrapper {
    position: initial;
  }
  }
`

const PhoneImg = styled.div`
  width: 100%;
  max-width: 500px;
  justify-self: end;
  margin-top: 100px;
  /* background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MDYiIGhlaWdodD0iNjA2IiB2aWV3Qm94PSIwIDAgNjA2IDYwNiI+CiAgPGNpcmNsZSBpZD0iY2lyY2xlIiBjeD0iMzAzIiBjeT0iMzAzIiByPSIzMDMiIGZpbGw9IiNlYmYxZmYiIG9wYWNpdHk9IjAuNTAyIi8+Cjwvc3ZnPgo=");
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: contain;  */
  @media screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    /* margin-top: 0px; */
    justify-self: center;
  }
`

const IconSection = styled.div`
  display: grid;
  grid-template: auto / 24px auto;
  grid-gap: ${(props) => props.theme.padding};
  align-items: center;
  justify-items: self-start;
  p {
    text-align: left;
  }
`;

const IconBubble = styled.div`
  display: flex;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.offWhite};
  align-items: center;
  justify-content: center;
`;

const HeroOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(147.65deg, rgba(245,245,255,0.75) 9.99%, rgba(41, 31, 97, 0.78) 78.07%);
`;

const HeroText = styled(FlexBlock)`
  position: relative;
  margin: 150px 0px 0px 0px;
  z-index: 1;
  text-align: left;
  button {
    margin-top: 20px;
  }
`;

const ImgContainer = styled.div`
  position: relative;
  width: 100%;
`

const ImgContainerInverted = styled(GridRowToTopOnTablet)`
  position: relative;
  width: 100%;
`

const ImgContainerRound = styled(ImgContainer)`
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow:  ${({ theme }) => theme.bsSpreadLarge};
  overflow: hidden;
`

const BgSwoop = styled.div`
position: absolute;
bottom: 0;
width: 100%;

  background-image: url(${props => props.bg});
  height: -webkit-fill-available;
background-position-x: center;
background-position-y: bottom;
background-repeat: no-repeat;
background-size: contain;
`
const BgSwoopFooter = styled(BgSwoop)`
position: relative;
margin-bottom: -2px;
`

const MiddleSection = styled(Section)`
overflow: visible;
background-image: url(${props => props.bg});
height: -webkit-fill-available;
background-position-x: center;
background-position-y: bottom;
background-repeat: no-repeat;
background-size: contain;
height: auto;
`

const MiddleSectionCTA = styled(Section)`
overflow: visible;
background-image: url(${props => props.bg});
height: -webkit-fill-available;
background-position-x: center;
background-position-y: center;
background-repeat: no-repeat;
background-size: cover;
height: 700px;
`

const StyledBGTitled = styled.div`
  position: absolute;
  top: 40px;
  height: 80%;
  width: 270px;
  background-color: rgba(47, 128, 237, 0.61);
  border-radius: 20px;
  transform: rotate(-10deg);
`

const PictureElement = styled.div`
  height: 75px;
  width: 75px;
  background-color: red;
  transform: rotate(15deg);
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.bsSpread};
`
const VideoPlayer = styled.video`
-webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
`